<ng-container [formGroup]="formGroupParent" >
  <div class="usr-info">
    <div class="usr-info__badge-container">
      @if(userAvatar(); as avatar) {
        <evc-avatar
          [avatar]="avatar"
          [attr.data-testid]="'avatar'"
          size="lg"
        ></evc-avatar>
      }

      <evc-button
        type="tertiary"
        class="usr-info__blue-btn"
        data-testid="upload"
        (click)="changeUserPicture($event)"
        [disabled]="true">
        <span>{{'PAGE_PROFILE.personal_info.cta_picture' | translate }}</span>
        <evc-svg-icon icon="plus" size="xs"></evc-svg-icon>
      </evc-button>
    </div>

    <div class="usr-info__form">
      <div class="usr-info__form-content">
        @for (item of ['givenName', 'surname']; track $index) {
          <evc-input
            [formControlName]="item"
            [name]="item"
            [label]="'PAGE_PROFILE.personal_info.'+item+''|translate"
            [placeholder]="'PAGE_PROFILE.personal_info.'+item+'_placeholder'|translate"
            [showError]="submited()"
            size="lg"
          ></evc-input>
        }
      </div>
    </div>

    <div class="usr-info__divider"></div>

    <div class="usr-info__form-content usr-info__center-content">
      <div class="usr-info__section-container">
        <label for="email" class="usr-info__desc-title">{{'PAGE_PROFILE.personal_info.email' | translate }}</label>
        <span>{{ email() }}</span>
      </div>

      <evc-svg-icon class="usr-info__lock" icon="lock" size="md"></evc-svg-icon>
    </div>

    <div class="usr-info__divider"></div>

    <div class="usr-info__row-container">
      <evc-input
        formControlName="phone"
        name="phone"
        label="{{'PAGE_PROFILE.personal_info.phone' | translate }}"
        placeholder="{{'PAGE_PROFILE.personal_info.phone_placeholder' | translate }}"
        [showError]="submited()"
      ></evc-input>
      <div class="usr-info__empty-space--md"></div>
    </div>

    <div class="usr-info__divider"></div>

    <div>
      <div class="usr-info__form-content">
        @if(displayAddress()) {
          <evc-address-form
            data-testid="address-form"
            [parentComponentName]="'user'"
            [formGroupParent]="formGroupParent"
            [currentAddress]="currentAddress()"
            [showError]="showAdressError()"
          ></evc-address-form>
        }
        @else {
          <div class="usr-info__section-container" data-testid="no-addresses">
            <label for="address" class="usr-info__label">{{'PAGE_PROFILE.personal_info.address' | translate }} ({{ 'labels.optional' | translate }})</label>
            <span class="usr-info__no-data">{{ 'PAGE_PROFILE.personal_info.no_address' | translate }}</span>
          </div>
        }

          @if(addressCTA() ; as cta) {
            <evc-button
              class="usr-info__blue-btn"
              data-testid="address-cta"
              [type]="cta.type"
              [rightIcon]="cta.icon"
              (click)="cta.action($event)"
            >
              <span>{{ cta.label | translate }}</span>
            </evc-button>
          }

      </div>
    </div>
  </div>
</ng-container>
