import type { OnInit, Signal, WritableSignal } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { RouterModule } from '@angular/router';

import type { AppIdentity, UserConfig } from '@evc/platform';
import { MOCK_USER_CONFIG, PlatformComponent, PlatformService } from '@evc/platform';
import type { Maybe, Menu } from '@evc/web-components';
import { SharedConfigService } from '@shared/services/config/config.service';
import { I18nService } from '@shared/services/i18n/i18n.service';

import { NavConfigService } from './services/nav-config/nav-config.service';

@Component({
  standalone: true,
  imports: [RouterModule, PlatformComponent],
  selector: 'evc-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  public leftbarConfig: WritableSignal<Menu[]>;
  public appIdentification: WritableSignal<AppIdentity>;
  public i18nService = inject(I18nService);
  readonly #platformService = inject(PlatformService);

  #config = inject(SharedConfigService);
  #navConfig = inject(NavConfigService);

  #mockUser = signal<Maybe<UserConfig>>(undefined);
  get mockUser():Signal<Maybe<UserConfig>> {
    return this.#mockUser.asReadonly();
  }
  get ready():Signal<boolean> {
    return this.#platformService.ready;
  }

  constructor() {
    this.leftbarConfig = this.#navConfig.leftbarConfig;
    this.appIdentification = this.#navConfig.appIdentity;
  }

  ngOnInit(): void {
    if (this.#config.get('DEBUG_DISABLE_AUTH')) {
      this.#mockUser.set(MOCK_USER_CONFIG);
      console.error('DEBUG_DISABLE_AUTH is enabled. Mock user is set.');
    }
  }
}
