<div class="app">
  <evc-platform
    class="app__platform"
    [inputAppIdentification]="appIdentification()"
    [inputLeftBar]="leftbarConfig()"
    [inputUserProfile]="mockUser()"
    [i18nAppService]="i18nService"
  >
    @if(ready()) {
      <main class="app__container" data-testid="app-container">
        <router-outlet></router-outlet>
      </main>
    }
  </evc-platform>
</div>
