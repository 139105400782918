const APPS = ['Drive', 'IPM', 'Vanta', 'AeroView', 'WindView', 'WeldSight', 'ViSOL'];
const LICENCE_TYPES = ['Standard', 'Analysis', 'Default'];
const LICENCE_MODELS = ['Single User', 'Base bundle', 'Node locked', 'Perpetual', 'Floating'];

function getRandom(arr:number|unknown[] = 1): string | number {
  if (typeof arr === 'number') return Math.floor(Math.random() * (arr + 1));

  return arr[Math.floor(Math.random() * arr.length)] as string;
}

const getDate = () => Math.random() < 0.2
  ? new Date(`20${getRandom(24)}-${getRandom(12)}-${getRandom(30)} 00:00:00`).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
  : '--';

const getApp = (): string => getRandom(APPS) as string;

const getLicenseType = () => ({
  value: { mainText: getRandom(LICENCE_TYPES) },
  ...Math.random() < 0.2 ? { text: 'Trial' } : {},
});

const getLicensingModel = () => ({
  value: { mainText: getRandom(LICENCE_MODELS) },
  disabled: Math.random() < 0.2,
});

export const MOCK_APPLICATIONS = Array.from({ length: 100 }, () => {
  const app = getApp();

  return {
    app: { value: { mainText: app }, iconLeft: { svgPath: `/assets/apps/${app.toLowerCase()}.svg#${app.toLowerCase()}` }, style: 'bold' },
    licenseType: getLicenseType(),
    licensingModel: getLicensingModel(),
    expiration: { value: { mainText: getDate() } },
  };
});
