import type { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from '@env';

import { AuthFailureGuard, AuthLogoutGuard } from '@evc/platform';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';

import { ApplicationsPageComponent } from './pages/applications/applications.component';
import { ProfilePageComponent } from './pages/profile/profile.component';
import { SecurityPrivacyPageComponent } from './pages/secu-privacy/secu-privacy.component';

const DEBUG_DISABLE_GUARD_ON_DEV = environment.DEBUG_DISABLE_AUTH
  ? { canActivate: undefined }
  : {};

export const appRoutes: Route[] = [
  {
    path: 'logout',
    canActivate: [AuthLogoutGuard],
    component: PageNotFoundComponent,
    data: { logout: true },
  },
  {
    path: 'auth-fail',
    canActivate: [AuthFailureGuard],
    component: PageNotFoundComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    component: ProfilePageComponent,
    canActivate: [MsalGuard],
    ...DEBUG_DISABLE_GUARD_ON_DEV,
  },
  {
    path: 'security-privacy',
    component: SecurityPrivacyPageComponent,
    canActivate: [MsalGuard],
    ...DEBUG_DISABLE_GUARD_ON_DEV,
  },
  {
    path: 'applications',
    component: ApplicationsPageComponent,
    canActivate: [MsalGuard],
    ...DEBUG_DISABLE_GUARD_ON_DEV,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [MsalGuard],
    ...DEBUG_DISABLE_GUARD_ON_DEV,
  },
];
