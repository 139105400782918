import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';

import { AuthService, OrganizationsService, UserService } from '@evc/platform';
import {
  CloseOnClickOutsideDirective,
  FrameComponent,
  InfoBannerComponent,
  ModalComponent,
  NavButtonComponent,
  SvgIconComponent,
} from '@evc/web-components';
import { TranslatePipe } from '@shared/services/i18n/i18n.service';

@Component({
  standalone: true,
  selector: 'evc-secu-privacy',
  templateUrl: 'secu-privacy.component.html',
  styleUrls: ['./secu-privacy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslatePipe,
    FrameComponent,
    NavButtonComponent,
    SvgIconComponent,
    InfoBannerComponent,
    ModalComponent,
    CloseOnClickOutsideDirective,
  ],
})
export class SecurityPrivacyPageComponent {
  public privacyPolicyLink = 'https://www.evidentscientific.com/en/privacy/';
  // TODO : dynamic date translation (french format vs english format)
  public lastUpdatedPassword = new Date('2024-03-17').toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  public showDeleteAccountModal = false;

  #authService = inject(AuthService);
  #userService = inject(UserService);
  #orgService = inject(OrganizationsService);

  public canDeleteAccount = computed(() => {
    const profile = this.#userService.profile();
    if (!profile) {
      return false;
    }

    return this.#orgService.entries().length === 0;
  });
  public changePasswordClick():void {
    // eslint-disable-next-line no-console
    console.log('Change password clicked');
  }

  public reviewPolicyClick():void {
    // eslint-disable-next-line no-console
    console.log('Review policy clicked');
  }

  public downloadAccountData():void {
    // eslint-disable-next-line no-console
    console.log('Download account data clicked');
  }

  public deleteAccount():void {
    this.showDeleteAccountModal = true;
  }

  public closeDeleteAccountModal():void {
    this.showDeleteAccountModal = false;
  }

  public confirmDeleteAccount():void {
    this.#authService.logout();
    this.showDeleteAccountModal = false;
  }
}
