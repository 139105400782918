<div class="secu-privacy">
  <evc-frame>
    <div frame-title class="evc-app-title">
      <span class="evc-page-title">{{'PAGE_SECURITY.title' | translate }}</span>
    </div>

    <div frame-content class="evc-page-container">
      <div class="evc-page-infos">
        <span class="evc-page-small-space secu-privacy__content-title">{{'PAGE_SECURITY.account_security.title' | translate }}</span>
        <div class="secu-privacy__main-content">
          <div class="secu-privacy__paragraph">
            <p class="secu-privacy__paragraph--title">{{'PAGE_SECURITY.account_security.password' | translate }}</p>
            <span>{{'PAGE_SECURITY.account_security.last_upate' | translate: {date: lastUpdatedPassword} }}</span>
          </div>
          <evc-button
           type="tertiary"
           (click)="changePasswordClick()"
           >
           <div class="secu-privacy__btn-content">
            <span class="secu-privacy__small-text">{{'PAGE_SECURITY.account_security.cta_change_password' | translate }}</span>
            <evc-svg-icon icon="pen-to-square" size="sm"></evc-svg-icon>
           </div>
          </evc-button>
        </div>
        <div class="evc-page-small-space"></div>
      </div>

      <div class="evc-page-divider"></div>

      <div class="evc-page-infos">
        <span class="evc-page-small-space secu-privacy__content-title">{{'PAGE_SECURITY.privacy_comm.title' | translate }}</span>
        <div class="secu-privacy__main-content">
          <div class="secu-privacy__paragraph">
            <p class="secu-privacy__paragraph--title">{{'PAGE_SECURITY.privacy_comm.privacy_policy' | translate }}</p>
            <span>
              {{'PAGE_SECURITY.privacy_comm.desc' | translate }}
            </span>
            <a [href]="privacyPolicyLink" class="secu-privacy__link" target="_blank">{{'PAGE_SECURITY.privacy_comm.link' | translate }}</a>
          </div>
        </div>
        <div class="evc-page-small-space"></div>
      </div>

      <div class="evc-page-divider"></div>

      <div class="evc-page-infos">
        <span class="evc-page-small-space secu-privacy__content-title">{{'PAGE_SECURITY.account_data.title' | translate }}</span>
        <div class="secu-privacy__main-content">
          <div class="secu-privacy__paragraph">
            <div class="secu-privacy__main-content">
              <span>
                {{'PAGE_SECURITY.account_data.desc_account_data' | translate }}
              </span>
              <evc-button
                type="tertiary"
                (click)="downloadAccountData()"
              >
                <div class="secu-privacy__btn-content">
                  <span class="secu-privacy__small-text">{{'PAGE_SECURITY.account_data.cta_download' | translate }}</span>
                  <evc-svg-icon icon="arrow-down-to-bracket" size="sm"></evc-svg-icon>
                </div>
              </evc-button>
            </div>

            <div class="evc-page-divider"></div>

            <div class="secu-privacy__main-content">
              <span>
                {{'PAGE_SECURITY.account_data.desc_delete_account' | translate }}
              </span>
              <evc-button
                type="error-tertiary"
                [disabled]="!canDeleteAccount()"
                (click)="deleteAccount()"
              >
                <div class="secu-privacy__btn-content">
                  <span class="secu-privacy__small-text">{{'PAGE_SECURITY.account_data.cta_delete' | translate }}</span>
                  <evc-svg-icon icon="xmark" size="sm"></evc-svg-icon>
                </div>
              </evc-button>
            </div>

            <evc-info-banner
              class="secu-privacy__info-banner"
              type="info"
              icon="circle-question"
              message="{{'PAGE_SECURITY.account_data.additional_info' | translate }}"
              [closable]="false"
            ></evc-info-banner>
          </div>
        </div>
        <div class="evc-page-small-space"></div>
      </div>
    </div>
  </evc-frame>
</div>

@if (showDeleteAccountModal) {
  <evc-modal
    evcCloseOnClickOutside
    width="25%"
    maxWidth="380px"
    minWidth="225px"
    [showReduceButton]="false"
    (clickedOutside)="closeDeleteAccountModal()"
    (closeModal)="closeDeleteAccountModal()"
  >
    <span modal-title>{{'PAGE_SECURITY.delete_account.question_title' | translate }}</span>
    <span modal-content>
      {{'PAGE_SECURITY.delete_account.question' | translate }}
    </span>
    <div modal-actions class="secu-privacy__modal-actions">
      <evc-button
        type="tertiary"
        variant="semi"
        (click)="closeDeleteAccountModal()"
      >
        <span class="secu-privacy__modal-actions--cancel">{{'cancel' | translate }}</span>
      </evc-button>
      <evc-button
        type="error-primary"
        variant="semi"
        (click)="confirmDeleteAccount()"
      >
      <span>{{'PAGE_SECURITY.delete_account.cta' | translate }}</span>
      </evc-button>
    </div>
  </evc-modal>
}
